<template>
  <view-item-setting>
    <template #operation>
      <en-dropdown @command="operation.export.command">
        <en-button type="primary">导出</en-button>
        <template #dropdown>
          <en-dropdown-item command="workorder">工单统计导出</en-dropdown-item>
          <en-dropdown-item command="mobile">手机工单导出</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>
    <flex-box>
      <template #default="{ height }">
        <form-query :method="table.get" :model="form.data">
          <en-form-item label="日期范围">
            <en-date-picker
              v-model:start="form.data.startDate"
              v-model:end="form.data.endDate"
              type="daterange"
              value-format="YYYY-MM-DD"
            ></en-date-picker>
          </en-form-item>
          <en-form-item label="ERP切换状态" prop="enocherpConverted">
            <select-maintain
              :ajax="{
                action: 'GET /enocloud/common/lookup/:lookupType',
                params: (params) => (params.paths = ['FLAG']),
                convert: (data) => {
                  return data.map((item) => {
                    if (item.code === 'Y') item.message = '已切换'
                    else item.message = '未切换'
                    return item
                  })
                }
              }"
              :props="{ label: 'message', value: '' }"
              v-model="form.data.enocherpConverted"
              value-key="code"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="已结算工单数">
            <select-maintain
              :model-value="form.data.countType"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['SVSCNT']) }"
              :props="{ label: 'message', value: 'code' }"
              value-key="code"
              class="w-full"
            ></select-maintain>
          </en-form-item>
        </form-query>
        <table-dynamic
          :height="600"
          code="SVCCTSTTSFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #OPERATION="{ row }: { row: EnospraySummaryDefinitions['ServiceCountStatisticsDto'] }">
            <en-button @click="table.operation.export.click(row)" type="primary" text>导出明细</en-button>
          </template>
          <template #EVALUATION="{ row }: { row: EnospraySummaryDefinitions['ServiceCountStatisticsDto'] }">
            <span v-if="row.evaluation === '正常使用'" style="color: #67c23a">{{ row.evaluation }}</span>
            <span v-if="row.evaluation === '间接使用'" style="color: #e6a23c">{{ row.evaluation }}</span>
            <span v-if="row.evaluation === '不使用'" style="color: #f56c6c">{{ row.evaluation }}</span>
          </template>
          <template #TENANT_SHORT_NAME="{ row }: { row: EnospraySummaryDefinitions['ServiceCountStatisticsDto'] }">
            <en-button v-if="store.accessRightsHash.SPRAY_TENANT_QUERY" @click="table.editTenant.click(row)" type="primary" text>{{
              row.tenant?.shortName
            }}</en-button>
            <span v-else>{{ row.tenant?.shortName }}</span>
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </view-item-setting>
</template>

<script lang="ts">
import dayjs from 'dayjs'
const formatterDate = (date: any, fmt: any) => {
  let nowDate = {
    yyyy: date.getFullYear(),
    MM: date.getMonth() + 1,
    dd: date.getDate(),
    hh: date.getHours(),
    mm: date.getMinutes(),
    ss: date.getSeconds()
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in nowDate) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? nowDate[k] : ('00' + nowDate[k]).substr(('' + nowDate[k]).length))
    }
  }
  return fmt
}
export default factory({
  config: {
    children: {
      operation: {
        export: {
          ajax: {
            workorder: {
              action: 'GET /enospray/service/countstatistics/export',
              params(params) {
                params.payload = this.form.data
              }
            },
            mobile: {
              action: 'GET /enospray/summary/business/appusage/export',
              params(params) {
                params.payload = this.form.data
              }
            }
          },
          async command(option: string) {
            switch (option) {
              case 'workorder':
                await this.operation.export.workorder()
                this.store.openDownload()
                break
              case 'mobile':
                await this.operation.export.mobile()
                this.store.openDownload()
                break
            }
          }
        }
      },
      form: {
        is: 'form',
        data: {
          tenantName: '',
          // tenantId: '',
          startDate: dayjs().format('YYYY-MM-DD'),
          endDate: dayjs().format('YYYY-MM-DD'),
          countType: 'A',
          parentCode: '',
          salesman: '',
          // signedBy: '',
          erpConversionIntention: '',
          sprayTenantStatus: '',
          enocherpConverted: '',
          executedBy: '',
          supervisor: '',
          importStartDate: '',
          importEndDate: '',
          enochErpUsagePurposeCode: ''
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enospray/service/countstatistics',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        config: {
          TENANT_SHORT_NAME: { header: { filter: { type: 'text', field: 'tenantName' } } },
          SIGNED_BY: { header: { filter: { type: 'text', field: 'signedBy' } } },
          SPRAY_TENANT_SALESMAN: { header: { filter: { type: 'text', field: 'salesman' } } },
          MANAGER: { header: { filter: { type: 'text', field: 'executedBy' } } },
          SUPERVISOR: { header: { filter: { type: 'text', field: 'supervisor' } } },
          IMPORT_DATE: { header: { filter: { type: 'date', field: ['importStartDate', 'importEndDate'], props: { type: 'daterange' } } } },
          ENOCH_ERP_USAGE_PURPOSE: {
            header: {
              filter: {
                type: 'select',
                field: 'enochErpUsagePurposeCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['EHERPUSPPE'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SPRAY_TENANT_STATUS: {
            header: {
              filter: {
                type: 'select',
                field: 'sprayTenantStatus',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['SRYTNTSTAT'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          NORMAL_SPRAY_MAINTENANCE_COUNT: {
            header: {
              filter: {
                type: 'select',
                field: 'countType',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['SVSCNT'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SPRAY_TENANT_PARENT: {
            header: {
              filter: {
                type: 'select',
                field: 'parentCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => (params.paths = ['TNTPRT'])
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          }
        },
        children: {
          operation: {
            ajax: {
              get: {
                action: 'GET /enospray/service/countstatistics/details/:tenantId/export'
              }
            },
            export: {
              async click(row: EnospraySummaryDefinitions['ServiceCountStatisticsDto']) {
                await this.table.operation.get({ paths: [row.tenant?.id], payload: { ...this.form.data, tenantId: row.tenant?.id } })
                this.store.openDownload()
              }
            }
          }
        },
        editTenant: {
          click(row: EnospraySummaryDefinitions['ServiceCountStatisticsDto']) {}
        }
      }
    }
  },

  mounted() {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let startDate = [year, '0' + month, '01']
    let endDate = new Date(Date.now())
    endDate = formatterDate(endDate, 'yyyy-MM-dd')

    this.form.data.startDate = startDate.join('-')
    this.form.data.endDate = endDate.toString()
    this.table.get()
  }
})
</script>
